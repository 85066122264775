import React from "react";

export const SQL: React.FC<{}> = () => {
  return(
    <svg version="1.1" x="0px" y="0px"
      viewBox="0 0 360 360" className="skillIcon">
    <g>
      <path d="M344.377,125.605h-48.754V77.834L212.601,0H15.623v360h280v-51.668h48.754V125.605z M314.377,278.332h-210
        V155.605h210V278.332z"/>
      <path d="M149.671,235.093c-2.516,0-4.968-0.306-7.36-0.92c-2.392-0.613-4.57-1.364-6.532-2.254
        c-1.963-0.888-3.665-1.778-5.106-2.668c-1.442-0.888-2.438-1.61-2.99-2.162l-5.612,10.948c3.802,2.7,8.096,4.784,12.88,6.256
        c4.784,1.472,9.628,2.208,14.536,2.208c3.189,0,6.24-0.354,9.154-1.058c2.912-0.705,5.488-1.824,7.728-3.358
        c2.238-1.532,4.017-3.542,5.336-6.026c1.318-2.484,1.978-5.474,1.978-8.97c0-3.004-0.492-5.535-1.472-7.59
        c-0.982-2.054-2.378-3.818-4.186-5.29c-1.81-1.472-4.018-2.698-6.624-3.68c-2.607-0.98-5.506-1.9-8.694-2.76
        c-2.454-0.612-4.616-1.196-6.486-1.748c-1.872-0.552-3.419-1.164-4.646-1.84c-1.228-0.674-2.162-1.456-2.806-2.346
        c-0.644-0.888-0.966-2.008-0.966-3.358c0-2.33,0.858-4.14,2.576-5.428c1.716-1.288,4.324-1.932,7.82-1.932
        c1.962,0,3.895,0.246,5.796,0.736c1.9,0.492,3.664,1.09,5.29,1.794c1.625,0.706,3.004,1.426,4.14,2.162
        c1.134,0.736,1.916,1.32,2.346,1.748l5.612-10.304c-2.884-1.962-6.256-3.664-10.12-5.106c-3.864-1.44-8.096-2.162-12.696-2.162
        c-3.312,0-6.41,0.445-9.292,1.334c-2.884,0.89-5.414,2.193-7.59,3.91c-2.178,1.718-3.88,3.864-5.106,6.44
        c-1.228,2.576-1.84,5.52-1.84,8.832c0,2.516,0.382,4.678,1.15,6.486c0.767,1.81,1.917,3.404,3.45,4.784
        c1.532,1.38,3.45,2.576,5.75,3.588c2.3,1.012,5.014,1.948,8.142,2.806c2.576,0.736,4.906,1.412,6.992,2.024
        c2.084,0.614,3.864,1.288,5.336,2.024c1.472,0.736,2.606,1.595,3.404,2.576c0.796,0.982,1.196,2.178,1.196,3.588
        C160.158,232.855,156.663,235.093,149.671,235.093z"/>
      <path d="M188.079,235.92c2.79,3.068,6.133,5.552,10.028,7.452c3.894,1.902,8.233,2.852,13.018,2.852
        c3.128,0,6.072-0.414,8.832-1.242c2.76-0.828,5.336-1.978,7.729-3.45l3.771,4.232h11.96l-8.924-10.028
        c2.698-3.004,4.799-6.44,6.302-10.304c1.502-3.864,2.254-7.912,2.254-12.144c0-4.108-0.768-8.156-2.3-12.144
        c-1.533-3.986-3.68-7.544-6.439-10.672c-2.761-3.128-6.088-5.658-9.982-7.59c-3.896-1.932-8.204-2.898-12.926-2.898
        c-4.601,0-8.864,0.905-12.788,2.714c-3.926,1.81-7.314,4.248-10.166,7.314c-2.853,3.067-5.092,6.594-6.716,10.58
        c-1.626,3.987-2.438,8.158-2.438,12.512c0,4.172,0.767,8.25,2.3,12.236C183.126,229.328,185.287,232.855,188.079,235.92z
        M193.415,205.146c0.828-2.606,2.038-4.952,3.634-7.038c1.595-2.085,3.588-3.756,5.98-5.014c2.392-1.256,5.12-1.886,8.188-1.886
        c2.944,0,5.597,0.614,7.958,1.84c2.36,1.228,4.354,2.852,5.98,4.876c1.624,2.024,2.882,4.355,3.771,6.992
        c0.889,2.638,1.334,5.368,1.334,8.188c0,2.392-0.337,4.754-1.012,7.084c-0.676,2.332-1.626,4.478-2.852,6.44l-4.508-5.06h-11.961
        l9.937,11.224c-1.228,0.676-2.562,1.212-4.002,1.61c-1.442,0.399-2.99,0.598-4.646,0.598c-3.006,0-5.689-0.612-8.05-1.84
        c-2.361-1.226-4.355-2.852-5.98-4.876c-1.626-2.024-2.868-4.354-3.727-6.992c-0.859-2.636-1.288-5.366-1.288-8.188
        C192.173,210.406,192.587,207.754,193.415,205.146z"/>
      <polygon points="298.708,234.633 265.405,234.633 265.405,180.445 252.708,180.445 252.708,245.765 
        298.708,245.765 	"/>
    </g>
    </svg>
  );
}