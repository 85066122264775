import React from "react";

export interface Props {
  reff?: React.MutableRefObject<SVGSVGElement>;
}

export const Circle3: React.FC<Props> = ({ reff }) => {
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      version="1.1"
      id="circle3"
      className="circle"
      ref={reff}
      > 
        <path
          className="circlePath"
          style={{fill:"#ffffff",fillRule:"evenodd",fillOpacity:1, width: 0, height: 0}}
          d="M 90 50 A 40 40 0 0 1 89.960938 51.767578 L 99.951172 52.208984 A 50 50 0 0 0 100 50 L 90 50 z M 89.845703 53.486328 
          A 40 40 0 0 1 89.654297 55.244141 L 99.568359 56.554688 A 50 50 0 0 0 99.810547 54.357422 L 89.845703 53.486328 z M 89.392578 
          56.947266 A 40 40 0 0 1 89.044922 58.679688 L 98.808594 60.849609 A 50 50 0 0 0 99.240234 58.683594 L 89.392578 56.947266 z 
          M 88.636719 60.353516 A 40 40 0 0 1 88.142578 62.050781 L 97.677734 65.0625 A 50 50 0 0 0 98.296875 62.941406 L 88.636719 
          60.353516 z M 87.585938 63.681641 A 40 40 0 0 1 86.945312 65.328125 L 96.183594 69.160156 A 50 50 0 0 0 96.984375 67.101562 
          L 87.585938 63.681641 z M 86.25 66.90625 A 40 40 0 0 1 85.46875 68.490234 L 94.337891 73.113281 A 50 50 0 0 0 95.316406 
          71.132812 L 86.25 66.90625 z M 84.640625 70 A 40 40 0 0 1 83.722656 71.511719 L 92.15625 76.890625 A 50 50 0 0 0 93.302734 
          75.001953 L 84.640625 70 z M 82.765625 72.945312 A 40 40 0 0 1 81.71875 74.369141 L 89.652344 80.462891 A 50 50 0 0 0 
          90.958984 78.681641 L 82.765625 72.945312 z M 80.638672 75.712891 A 40 40 0 0 1 79.474609 77.041016 L 86.845703 83.802734 A 
          50 50 0 0 0 88.302734 82.142578 L 80.638672 75.712891 z M 78.28125 78.285156 A 40 40 0 0 1 77.005859 79.507812 L 83.759766 
          86.886719 A 50 50 0 0 0 85.355469 85.359375 L 78.28125 78.285156 z " 
        />
    </svg>
  )
}