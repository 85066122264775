import React from "react";

export const GIMP: React.FC<{}> = () => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"  
      viewBox="0 0 48 48" 
      width="48px" 
      height="48px"
      className="skillIcon"
    >
      <radialGradient 
        id="LoYOxvrky7jpmBj9eYIMBa" 
        cx="22.415" 
        cy="20.182" 
        r="17.612" 
        gradientTransform="matrix(1 0 0 .9978 0 .009)" 
        gradientUnits="userSpaceOnUse"
      >
        <stop 
          offset="0" 
          stopColor="#a69d88"
        />
        <stop
          offset="1" 
          stopColor="#3e3a2e"
        />
      </radialGradient>
      <path 
        fill="url(#LoYOxvrky7jpmBj9eYIMBa)" 
        d="M8,20.962c0,0,2-0.998,2-2.993s0-9.978,0-9.978s3.959,8.098,14.037,6.54	
        c9.46-1.462,13.385-4.982,15.5-10.264c0.142-0.356,0.66-0.307,0.722,0.071C41.28,10.564,43.23,33.274,26,33.933	
        c0,0-10,0.998-19-6.984C8,24.953,8,20.962,8,20.962z"
      />
      <radialGradient 
        id="LoYOxvrky7jpmBj9eYIMBb" 
        cx="4.477" 
        cy="18.886" 
        r="5.596" 
        gradientTransform="matrix(.8717 -.5701 .5586 .8542 -9.83 6.704)" 
        gradientUnits="userSpaceOnUse"
      >
        <stop 
          offset="0" 
          stopColor="#7d8587"
        />
        <stop 
          offset="1" 
          stopColor="#323538"
        />
      </radialGradient>
      <path 
        fill="url(#LoYOxvrky7jpmBj9eYIMBb)" 
        d="M9.375,19.825c2.009,3.072,1.881,6.71-0.286,8.127s-5.551,0.075-7.56-2.996	
        s-1.881-6.71,0.286-8.127S7.366,16.754,9.375,19.825z"
      />
      <radialGradient 
        id="LoYOxvrky7jpmBj9eYIMBc" 
        cx="23.16" 
        cy="15.649" 
        r="5.755" 
        gradientTransform="matrix(.9876 0 0 1 .372 0)" 
        gradientUnits="userSpaceOnUse"
      >
        <stop 
          offset="0" 
          stopColor="#fafafb"
        />
        <stop 
          offset="1" 
          stopColor="#c8cdd1"
        />
      </radialGradient>
      <ellipse 
        cx="24.074" 
        cy="17.5" 
        fill="url(#LoYOxvrky7jpmBj9eYIMBc)" 
        rx="5.926" 
        ry="5.5"
      />
      <radialGradient 
        id="LoYOxvrky7jpmBj9eYIMBd" 
        cx="13.213" 
        cy="17.287" 
        r="3.646" 
        gradientUnits="userSpaceOnUse"
      >
        <stop 
          offset="0" 
          stopColor="#fafafb"
        />
        <stop 
          offset="1" 
          stopColor="#c8cdd1"
        />
      </radialGradient>
      <ellipse 
        cx="13.787" 
        cy="18.5" 
        fill="url(#LoYOxvrky7jpmBj9eYIMBd)" 
        rx="3.787" 
        ry="3.5"
      />
      <circle 
        cx="25.5" 
        cy="18.5" 
        r="2.5" 
        fill="#999"
      />
      <circle 
        cx="25.5" 
        cy="18.5" 
        r="1.5" 
        fill="#323537"
      />
      <circle 
        cx="14.545" 
        cy="19.168" 
        r="1.737" 
        fill="#999"
      />
      <circle 
        cx="14.545" 
        cy="19.168" 
        r=".827" 
        fill="#323537"
      />
      <circle 
        cx="13.466" 
        cy="18.041" 
        r="1.11" 
        fill="#fff"
      />
      <linearGradient 
        id="LoYOxvrky7jpmBj9eYIMBe" 
        x1="2.266" 
        x2="4.182" 
        y1="17.558" 
        y2="19.898" 
        gradientUnits="userSpaceOnUse"
      >
        <stop 
          offset="0" 
          stopColor="#caced2"
        />
        <stop 
          offset="1" 
          stopColor="#8f979e"
        />
      </linearGradient>
      <circle 
        cx="3.543" 
        cy="19.118" 
        r="1.882" 
        fill="url(#LoYOxvrky7jpmBj9eYIMBe)"
      />
      <circle 
        cx="23.761" 
        cy="16.894" 
        r="1.665" 
        fill="#fff"
      />
      <linearGradient 
        id="LoYOxvrky7jpmBj9eYIMBf" 
        x1="38.829" 
        x2="36.996" 
        y1="9.327" 
        y2="16.812" 
        gradientUnits="userSpaceOnUse"
      >
        <stop 
          offset="0" 
          stopColor="#77705f"
        />
        <stop 
          offset="1" 
          stopColor="#4b473a"
        />
      </linearGradient>
      <path 
        fill="url(#LoYOxvrky7jpmBj9eYIMBf)" 
        d="M39.883,4.932c0,0-1.149,4.452-2.872,6.798s-1.149,5.074,0.574,5.457	c1.723,0.383,0.91-0.766,
        0.239-1.005c-0.67-0.239-1.532-2.011-0.191-3.973C38.973,10.246,39.644,7.039,39.883,4.932z"
      />
      <path 
        fill="#181612" 
        d="M31.697,28.485c0,0,0-2.298-2.394-2.489c0,0,0.748,0.299,1.2,0.984	c-3.043,2.676-8.907,1.602-8.907,1.602c5.074,1.394,8.234,0.157,
        9.257-0.367C31.323,28.716,31.697,28.485,31.697,28.485z"
      />
      <linearGradient 
        id="LoYOxvrky7jpmBj9eYIMBg" 
        x1="34.596" 
        x2="33.202" 
        y1="30.031" 
        y2="32.079" 
        gradientUnits="userSpaceOnUse"
      >
        <stop 
          offset="0" 
          stopColor="#c26715"
        />
        <stop 
          offset=".508" 
          stopColor="#b85515"
        />
        <stop 
          offset="1" 
          stopColor="#ad3f16"
        />
      </linearGradient>
      <path 
        fill="url(#LoYOxvrky7jpmBj9eYIMBg)" 
        d="M30.644,27.672c1.532,0.718,8.234,5.314,8.234,5.314s-0.862,1.005-1.58,2.011	c-1.819-0.957-7.915-6.223-7.915-6.223C29.287,28.294,30.053,27.432,30.644,27.672z"
      />
      <linearGradient 
        id="LoYOxvrky7jpmBj9eYIMBh" 
        x1="42.047" 
        x2="40.275" 
        y1="33.728"
        y2="36.112" 
        gradientUnits="userSpaceOnUse"
      >
        <stop 
          offset="0" 
          stopColor="#fafafb"
        />
        <stop 
          offset="1" 
          stopColor="#c8cdd1"
        />
      </linearGradient>
      <path 
        fill="url(#LoYOxvrky7jpmBj9eYIMBh)" 
        d="M43.832,37.294l-4.955-4.309c-0.622-0.527-2.106,1.676-1.58,2.011l5.29,3.399L43.832,37.294z"
      />
      <linearGradient 
        id="LoYOxvrky7jpmBj9eYIMBi" 
        x1="46.488" 
        x2="43.937" 
        y1="40.911" 
        y2="42.684" 
        gradientUnits="userSpaceOnUse"
      >
        <stop 
          offset="0" 
          stopColor="#6d7479"
        />
        <stop 
          offset="1" 
          stopColor="#323538"
        />
      </linearGradient>
      <path 
        fill="url(#LoYOxvrky7jpmBj9eYIMBi)" 
        d="M48,45.576c0,0-6.234-1.787-5.677-6.846c0.128-1.161,1.349-1.412,1.349-1.412	s2.978-0.694,3.257,3.614C47.116,43.805,48,45.576,48,45.576z"
      />
    </svg>
  );
}